import React from 'react'
import styled from 'styled-components'

import { Container, ContentContainer, Row } from '../utils'
import { useTestimonialsData, useGeneralData } from '../../hooks';

const Title = styled.h2`
  padding-bottom: ${props => props.theme.spacing.sm};
  text-align: center;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding-top: ${props => props.theme.spacing.xs};
    padding-bottom: ${props => props.theme.spacing.xs};
  }
`

const ReviewCard = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  padding: ${props => props.theme.spacing.sm};
  text-align: center;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: ${props => props.theme.spacing.sm};
    padding: ${props => props.theme.spacing.xs};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Icon = styled.div`
  position: absolute;
  top: -25px;
  left: 50%;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  transform: translateX(-50%);
  background: ${props => props.theme.colors.greyLight};
  color: ${props => props.theme.colors.white};
  line-height: 69px;
  font-size: 50px;
  pointer-events: none;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 30px;
    width: 30px;
    top: -15px;
    font-size: 30px;
    line-height: 43px;
  }
`

const Quote = styled.div`
  > .review {
    font-weight: 300;
    margin: 15px 0 30px;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      margin: 15px 0;
    }
  }

  > .name {
    font-weight: bold;
  }
`

export const Testimonials = () => {
  const { testimonialsData } = useTestimonialsData()
  const { generalData } = useGeneralData()
  return (
    <Container bg="grey">
      <ContentContainer py="md">
        <Title>{generalData.testimonialsTitle}</Title>
        <Row col={3} gap={80} pt="sm" pb="sm" alignCenter>
          {testimonialsData.map((testimonial, i) => (
            <ReviewCard key={i}>
              <Icon>"</Icon>
              <Quote>
                <p className="review">"{testimonial.testimonial}"</p>
                <p className="name">{testimonial.name}</p>
              </Quote>
            </ReviewCard>
          ))}
        </Row>
      </ContentContainer>
    </Container>
  )
}
