import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { Hero, Services, Prices, Testimonials } from '../components/home'
import { CtaGiftVoucher } from '../components/common'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Sports & Remedial Massage Therapy" />
    <Hero />
    <Services />
    <Testimonials />
    <Prices />
    <CtaGiftVoucher />
  </Layout>
)

export default IndexPage
