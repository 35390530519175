import React from 'react'
import styled from 'styled-components'

import { ContentContainer, Container, Row, VerticalLine } from '../utils'
import { FilledButton } from '../common'

import { usePricedServicesData } from '../../hooks'

const PriceCard = styled.div`
  padding-top: ${props => props.theme.spacing.sm};
  padding-bottom: ${props => props.theme.spacing.sm};
  color: ${props => props.theme.colors.white};

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    text-align: center;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    padding-top: ${props => props.theme.spacing.xs};
    padding-bottom: ${props => props.theme.spacing.xs};

    &:last-child {
      border-bottom: none;
    }
  }

  > h2 {
    margin-bottom: 7px;
  }

  > p {
    padding-bottom: ${props => props.theme.spacing.xs};

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding-bottom: 0;
    }
  }
`

export const Prices = () => {
  const { pricedServicesData } = usePricedServicesData()
  return (
    <Container bg="primary">
      <ContentContainer py="md">
        <Row col={3} gap={240}>
          {pricedServicesData.pricing
            .map(pricing => (
              <PriceCard key={pricing.time}>
                <h2>{pricing.time}min</h2>
                <h4>${pricing.price}</h4>
                <p className="text">{pricing.description}</p>
                <FilledButton to="/book" className="-dark -mobileCenter" state={{ productId: pricing.timelyProductId }}>
                  Book Now
                </FilledButton>
              </PriceCard>
            ))
            .reduce((prev, curr, i) => [
              prev,
              <VerticalLine key={i} bg="rgba(255, 255, 255, 0.3)">
                <div />
              </VerticalLine>,
              curr,
            ])}
        </Row>
      </ContentContainer>
    </Container>
  )
}
