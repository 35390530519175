import React from 'react'
import styled from 'styled-components'

import { Container, ContentContainer, Row } from '../utils'
import { FilledButton, LinkButton, FancyText } from '../common'

import { usePricedServicesData } from '../../hooks'
import HMCBRLogo from '../../images/HMC_Background_Green.svg'

const ServicesText = styled.div`
  .-green {
    margin-top: ${props => props.theme.spacing.sm};

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      margin-top: ${props => props.theme.spacing.xs};
    }
  }
`

const BackgroundImage = styled.img`
  position: absolute;
  right: 100px;
  bottom: -40px;
  pointer-events: none;
  z-index: 1;
  max-width: 420px;
  width: 100%;
  opacity: 0.5;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`

const LinkRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .moreButton {
    padding-top: 5px;
    margin-left: 30px;
  }
`

export const Services = () => {
  const { pricedServicesData } = usePricedServicesData()
  return (
    <Container>
      <ContentContainer py="sm">
        <Row col={2} pt="sm" pb="sm" gap={100} alignCenter>
          <BackgroundImage src={HMCBRLogo} alt="HMC" />
          <ServicesText>
            <h6>{pricedServicesData.servicesMiniTitle}</h6>
            <h2>{pricedServicesData.title}</h2>
            <FancyText node={({ node }) => node} blocks={pricedServicesData._rawDescription} />
            <LinkRow>
              <FilledButton to="/services" className="-green">
                Our Services
              </FilledButton>
              <LinkButton to="/about" className="-green moreButton">
                More About Us
              </LinkButton>
            </LinkRow>
          </ServicesText>
        </Row>
      </ContentContainer>
    </Container>
  )
}
