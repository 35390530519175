import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

import { Container, Row } from '../utils'
import { FilledButton } from '../common'

import { useGeneralData } from '../../hooks'

const HeroContainer = styled(props => <Container {...props} />)`
  height: 80vh;
  max-height: 800px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 50px;
    padding-top: 90px;
    height: unset;
  }

  > * {
    height: 100%;
  }

  ${Row} {
    padding-left: ${props => props.theme.spacing.xs};
    padding-right: ${props => props.theme.spacing.xs};

    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      );
    }
  }
`

const HeroText = styled.div`
  padding-top: ${props => props.theme.spacing.sm};
  padding-bottom: ${props => props.theme.spacing.sm};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    color: ${props => props.theme.colors.white};
    padding-bottom: ${props => props.theme.spacing.xs};

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      text-align: center;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    order: 1;
  }
`

export const Hero = () => {
  const { generalData } = useGeneralData()
  return (
    <HeroContainer>
      <BackgroundImage fluid={generalData.image.asset.fluid} alt={generalData.altText}>
        <Row
          col={1}
          alignCenter
          css={`
            height: 100%;
          `}
        >
          <HeroText>
            <h1>{generalData.websiteTitle}</h1>
            <FilledButton to="/book">Book a Massage Today</FilledButton>
          </HeroText>
        </Row>
      </BackgroundImage>
    </HeroContainer>
  )
}
